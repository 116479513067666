var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('base-card',[_c('v-card-title',[(_vm.canCreate)?_c('div',{staticClass:"d-flex justify-space-between flex-wrap"},[_c('v-btn',{staticClass:"ma-2",attrs:{"dark":"","color":"primary"},on:{"click":function($event){return _vm.$router.push(_vm.baseRoute + 'create')}}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v(" Create New ")],1)],1):_vm._e()]),_c('v-card-title',[(_vm.page)?[_vm._v(" "+_vm._s(_vm.pageTitle)+" ")]:_vm._e(),_c('v-spacer'),_c('v-spacer'),_c('v-spacer'),_c('v-spacer'),_c('div',{staticClass:"d-flex"},[_vm._l((_vm.getModelFilterables),function(field){return _c('v-select',{key:field.id,staticClass:"mr-3",attrs:{"label":field.label,"items":field.options,"item-text":"name","item-value":"id","single-line":""},on:{"change":function($event){return _vm.search()}},model:{value:(_vm.filters[field.id]),callback:function ($$v) {_vm.$set(_vm.filters, field.id, $$v)},expression:"filters[field.id]"}})}),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.query),callback:function ($$v) {_vm.query=$$v},expression:"query"}})],2)],2),_c('v-data-table',{staticClass:"elevation-1 table-one",attrs:{"item-key":"id","footer-props":{ 'items-per-page-options': [100, 150, 300, -1] },"headers":_vm.headers,"items":_vm.items.length ? _vm.items : [],"page":_vm.getModelsPage,"pageCount":_vm.getModelsPagesNumber,"options":_vm.options,"server-items-length":_vm.total,"loading":_vm.getModelsLoading,"disable-sort":false,"disable-filtering":false},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([_vm._l((_vm.booleanHeaders),function(av2bh){return {key:_vm.combine(av2bh),fn:function(ref){
var item = ref.item;
return [(item[av2bh])?_c('v-icon',{key:av2bh},[_vm._v("mdi-check")]):_vm._e()]}}}),_vm._l((_vm.fileHeaders),function(av2fh){return {key:_vm.combine(av2fh),fn:function(ref){
var item = ref.item;
return [_c('div',{key:av2fh},[(_vm.isImage(item[av2fh]))?_c('v-img',{key:("" + av2fh + (item[_vm.page.primary_key])),staticClass:"previewable",attrs:{"src":_vm.getThumbnail(item[av2fh]),"max-height":"40","max-width":"40","alt":("" + av2fh + (item[_vm.page.primary_key]))},on:{"click":function($event){return _vm.openModal(("" + av2fh + (item[_vm.page.primary_key])))}}}):_vm._e(),_c('Lightbox',{attrs:{"src":_vm.getThumbnail(item[av2fh], true)},on:{"close":function($event){return _vm.$forceUpdate()}},model:{value:(_vm.modal[("" + av2fh + (item[_vm.page.primary_key]))]),callback:function ($$v) {_vm.$set(_vm.modal, ("" + av2fh + (item[_vm.page.primary_key])), $$v)},expression:"modal[`${av2fh}${item[page.primary_key]}`]"}})],1)]}}}),{key:"item.action",fn:function(ref){
var item = ref.item;
return [(_vm.modifiable(item))?_c('div',{staticClass:"d-flex"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([(_vm.canEdit)?{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"success","dark":"","icon":""},on:{"click":function($event){return _vm.$router.push(_vm.baseRoute + item[_vm.page.primary_key] + '/edit')}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-pencil-box-outline")])],1)]}}:null],null,true)},[_c('span',[_vm._v("Edit")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([(_vm.canDelete)?{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"danger","dark":"","icon":""},on:{"click":function($event){return _vm.confirmDelete({ model: _vm.model, id: item[_vm.page.primary_key] })}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-trash-can-outline")])],1)]}}:null],null,true)},[_c('span',[_vm._v("Delete")])])],1):_vm._e()]}}],null,true)})],1),_c('v-dialog',{attrs:{"max-width":"290"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h6"},[_vm._v(" Delete "+_vm._s(_vm.model)+" ")]),_c('v-card-text',[_vm._v(" The "+_vm._s(_vm.model)+" will be deleted permanently. Are you sure? ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"danger","text":"","loading":_vm.getModelsLoading},on:{"click":_vm.deleteModel}},[_vm._v(" Delete ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }